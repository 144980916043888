<template>
  <div id="app">
    <div class="app_wrap">
      <!-- 控件组 -->
      <div class="header_control_wrap">
        <!-- 小组赛/决赛 -->
        <div class="group_final_control_wrap">
          <div
            v-for="(item, index) in stageTitleList"
            :key="index"
            @click="switchStage(index)"
            :class="groupButtonClass(index)"
            style="margin-right: 20px"
          >
            {{ item }}
          </div>
        </div>
        <!-- 日期 -->
        <div class="day_control_wrap">
          <!-- 小组赛控件 -->
          <div class="group_day_control_wrap" v-if="stageIndex == 0">
            <div
              v-for="(item, index) in groupDayTitleList"
              :key="index"
              :class="dayButtonClass(index)"
              @click="switchGroupDay(index)"
            >
              {{ item }}
            </div>
          </div>
          <!-- 决赛控件 -->
          <div v-if="stageIndex == 1">
            <div class="final_day_control_wrap">
              <div
                v-for="(item, index) in finalDayTitleList"
                :key="index"
                :class="dayButtonClass(index)"
                @click="switchFinalDay(index)"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="table_wrap">
        <div class="table_title_wrap">
          <div
            class="table_title_item"
            v-for="(item, index) in tableTitle"
            :key="index"
            :style="{ flex: item == '战队' ? '2' : '1' }"
          >
            {{ item }}
          </div>
        </div>
        <div class="table_content_wrap">
          <div
            v-for="(row, rowIndex) in data"
            :key="rowIndex"
            class="table_row"
          >
            <div style="flex: 1">{{ rowIndex + 1 }}</div>
            <div style="flex: 2">{{ row.team }}</div>
            <div style="flex: 1">{{ row.m1 }}</div>
            <div style="flex: 1">{{ row.m2 }}</div>
            <div style="flex: 1">{{ row.m3 }}</div>
            <div style="flex: 1">{{ row.m4 }}</div>
            <div style="flex: 1">{{ row.m5 }}</div>
            <div style="flex: 1">{{ row.m6 }}</div>
            <div style="flex: 1" v-if="showScore1">{{ row.score1 }}</div>
            <div style="flex: 1">{{ row.score2 }}</div>
          </div>
        </div>
      </div>
      <div style="text-align: right; margin-top: 10px">
        <img src="/gameday.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { getScript } from "./jsonp.js";

export default {
  name: "App",
  components: {},
  data() {
    return {
      stageIndex: 0,
      groupDayIndex: 0,
      finalDayIndex: 0,
      stageTitleList: ["小组赛", "决赛"],
      groupDayTitleList: ["A组第一日", "A组第二日", "B组第一日", "B组第二日"],
      finalDayTitleList: ["第一日", "第二日", "第三日"],
      fullData: {},
    };
  },
  computed: {
    showScore1() {
      if (this.stageIndex == 0) {
        if (this.groupDayIndex == 0 || this.groupDayIndex == 2) {
          return false;
        } else {
          return true;
        }
      } else {
          if (this.finalDayIndex == 2) {
            return true;
          }
          return false;
        
      }
    },
    tableTitle() {
      if (this.stageIndex == 0) {
        if (this.groupDayIndex == 0 || this.groupDayIndex == 2) {
          return [
            "排名",
            "战队",
            "第1局",
            "第2局",
            "第3局",
            "第4局",
            "第5局",
            "第6局",
            "总分",
          ];
        } else {
          return [
            "排名",
            "战队",
            "第1局",
            "第2局",
            "第3局",
            "第4局",
            "第5局",
            "第6局",
            "当日总分",
            "总分",
          ];
        }
      }

      if (this.stageIndex == 1) {

        if (this.finalDayIndex == 2) {
          return [
            "排名",
            "战队",
            "第1局",
            "第2局",
            "第3局",
            "第4局",
            "第5局",
            "第6局",
            "当日总分",
            "决赛总分",
          ];
        
        }
       
          return [
            "排名",
            "战队",
            "第1局",
            "第2局",
            "第3局",
            "第4局",
            "第5局",
            "第6局",
            "总分",
          ];
        
      }
      return [];
    },
    data() {
      let key = "1";
      if (this.stageIndex == 0) {
        key = this.groupDayIndex + 1;
      } else {
        key = 5 + this.finalDayIndex;
      }
      return this.fullData[`${key}`];
    },
  },
  methods: {
    groupButtonClass(stage) {
      if (stage == this.stageIndex) {
        return "big_button active";
      } else {
        return "big_button";
      }
    },
    dayButtonClass(index) {
      if (this.stageIndex == 0) {
        if (index == this.groupDayIndex) {
          return "small_button active";
        } else {
          return "small_button";
        }
      }
      if (this.stageIndex == 1) {
        if (index == this.finalDayIndex) {
          return "small_button active";
        } else {
          return "small_button";
        }
      }
      return "small_button";
    },
    switchStage(stage) {
      this.stageIndex = stage;
    },
    switchGroupDay(index) {
      this.groupDayIndex = index;
    },
    switchFinalDay(index) {
      this.finalDayIndex = index;
    },
  },
  mounted() {
    let _this = this;
    getScript(
      `https://gd-pubg-data.oss-cn-beijing.aliyuncs.com/data/data.js?t=${Date.parse(
        new Date()
      )}`,
      "",
      function (e) {
        if (e) {
          console.log(e);
        } else {
          _this.fullData = window.rank;
        }
      }
    );
  },
};
</script>

<style lang="less">
// @font-face {
//   font-family: siyuan-bold;
//   src: url(/SourceHanSansCN-Bold.otf);
// }
// @font-face {
//   font-family: siyuan-light;
//   src: url(/SourceHanSansCN-Light.otf);
// }


body {
  margin: 0;
  overflow: hidden;
  background-image: url("/bg.png");
  background-size: 100%;
  font-family: "lucida grande", "lucida sans unicode", lucida, helvetica,
    "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
}
.app_wrap {
  width: 1100px;
  height: 995px;
  overflow: hidden;
  padding: 0 56px 0 56px;
  box-sizing: border-box;
  .header_control_wrap {
    margin-top: 20px;

    .group_final_control_wrap {
      width: 100%;
      height: 70px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .big_button {
        width: 315px;
        height: 70px;
        cursor: pointer;
        font-family: siyuan-bold;
        text-align: center;
        line-height: 70px;
        color: #111c28;
        font-size: 40px;
        background-image: url("/button-big.png");
      }
      .active {
        color: #641e0b !important;
        background-image: url("/button-big-active.png") !important;
      }
    }
    .day_control_wrap {
      margin-top: 18px;
      width: 100%;
      height: 50px;
      .group_day_control_wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .final_day_control_wrap {
        margin-left: auto;
        margin-right: auto;
        width: 788px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .small_button {
        width: 225px;
        height: 50px;
        cursor: pointer;
        font-size: 26px;
        line-height: 50px;
        color: #000000;
        text-align: center;
        font-family: siyuan-bold;
        background-image: url("/button.png");
      }
      .active {
        color: #641e0b !important;
        background-image: url("/button-active.png") !important;
      }
    }
  }

  .table_wrap {
    width: 100%;
    .table_title_wrap {
      box-sizing: border-box;
      width: 985px;
      height: 51px;
      margin: 19px auto 0 auto;
      background-image: url("/table_title.png");
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-align: center;
      .table_title_item {
        height: 51px;
        line-height: 51px;
        font-size: 20px;
        font-family: siyuan-light;
        color: #ffffff;
      }
    }
  }
  .table_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    height: 44px;
    border-bottom: 1px solid #324557;
    font-size: 20px;
    line-height: 44px;
    color: #ffffff;
    text-align: center;
    font-family: siyuan-light;
  }
}
</style>
