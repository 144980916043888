exports.getScript = function (url, pagename, callback) {
  callback = callback || function () {}
  let script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = url
  script.setAttribute('charset', 'UTF-8')
  script.setAttribute('data-page', pagename)
  script.setAttribute('async', 'true')
  script.onload = function () {
    document.head.removeChild(script)
    callback(null)
  }
  script.onerror = function (e) {
    callback(e)
  }
  document.head.appendChild(script)
}
